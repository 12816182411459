export const lang = {


    title:'欧莱雅元宇宙展厅',
    account: 'アカウント',
    password: 'パスワード',
    nickname: '昵称',

    accountInfo: 'アカウント名を入力してください。',
    passwordInfo: 'パスワードを入力してください。',
    passwordInfo1: '请再次输入密码',
    passwordInfo2: '两次输入密码不一致',
    nicknameInfo: '请输入昵称',
    login: 'ログイン',
    customer: 'ゲストログイン',
    register: '注册',
    language: '语言',
    zh: '中文',
    en: '英文',
    loginError1: 'アカウント名とパスワードを入力してください。',
    loginError2: '密码错误',
    loginError3: '账号不存在',

    loginError4: '请勿重复登录',

    loginError5: '账号不为手机号',
    loginError6: 'アカウント名またはパスワードが間違っています。',
    loginSuccess: 'ログイン成功',
    vJoinInSuccess: '音声が流れます。ご注意ください。',
    function1: "Expo场馆导览图",
    function2: "虚拟人物形象选择",
    function3: "交互动作",
    function4: '用户管理',
    logOut: 'ログアウト',
    close: 'クローズ',
    online: 'オンライン人数',
    logOutInfo: 'ログアウトしてよろしいでしょうか',

    AccName: [
        "性别",
        "配饰",
        "头发",
        "帽子",
        "套装",
        "发色",
        "肤色"
    ],
    sendError: 'メッセージを入力ください',
    sendInfo: '新しいメッセージの入力',
    send: '送信',
    setting: '設定',
    confirmPassword: 'パスワード',
    setOK: 'OK',
    setCancel: 'キャンセル',
    peopleOnline: '人オンライン',
    world: 'チャット',
    clientError: '接続できません、デバイスを確認してください。',
    videoError: 'カメラが見つかりません。接続を確認してください。',
    micError: 'マイクが見つかりません。接続を確認してください。',
    chatClearError: `管理者によってチャット履歴がクリアされました。`,
    shareError: `当前已有人分享屏幕`,
    saveInfo: '保存完了',
    roam1:'序厅',
    roam2:'虚拟直播厅',
    roam3:'预见新产研赛道展馆',
    roam4:'玩美新体验赛道展馆',
    roam5:'引领运营赛道展馆',
    action1:'いいね',
    action2:'拍手',
    action3:'スクリーンショット',
    action4:'起立',
    action5:'スクリーンを見る',
    action6:'戻る',
    functionMike:'マイク',
    functionVideo:'カメラ',
    functionShare:'画面共有',
    shotSuccess:'スクリーンショット成功',
    mailSendSuccess:'送信済み',
    mailSendError:'送信失敗',
    pwdCheckError:'パスワードが間違っています。',
    chgPwdSuccess:'設定しました。',

    decoration:'アバター変更',
    manager:'ホスト',
    reAnima:'動画再生',
    chgManager:'参加者の管理',
    mute:'ミュート',
    chgRoomPwd:'ドアPW編集',
    muteAll:'全体ミュート',
    chatClear:'チャット履歴のクリア',
    outRoom:'退室',
    setFreePassword:'会議室に自由入室する',
    resetPassword:'パスワードで会議室に入室する',

    reAnimaInfo:'動画リンクを入力してください。',
    reAnimaInfoHit:'ヒント',
    rePwdInfo:'パスワードを設定してください。',
    rePwdErr:'パスワードを入力してください。',
    confirm:'OK',
    cancel:'キャンセル',

    meetingError1:'会議の有効期限外です。',
    meetingError2:'音声がミュート中です。ONにしたい場合、管理者に申請してください。',
    meetingError3:'管理者により画面共有は停止されています。',
    meetingError4:'管理者によりカメラは停止されています。',
    meetingError5:'管理者によりミュートされています。',
    meetingError6:'管理者により入室禁止とされています。',
    meetingError7:'管理者により画面共有を停止されています。',
    meetingError8:'管理者によりカメラは停止されています。',
    meetingError9:'使用のブラウザでマイクの使用が許可されていません。',
    meetingError10:'使用のブラウザでカメラの使用が許可されていません。',
    meetingError11:'着席中はアバター変更ができません。',
    meetingError12:'管理者によりミュートされています。',
    meetingError13:'携帯番号を入力してください。',
    meetingError14:'管理者のみコンテンツ共有できます。',
    meetingError15:'会議機能が利用できません。',
    meetingError16:'満室です。時間を置いて入出してください。',
    meetingError17:'挙手は繰り返しできません。',
    meetingError18:'ニックネームで特殊記号は使用できません。',
    meetingError19:'ゲストログインの場合、チャットは使用できません。',
    meetingError20:'コンテンツ共有権限がありません。',
    meetingError21:'リクエストが拒否されました。',
    meetingError22:'退室しました。',
    meetingError23:'管理者により退室されました。',
    meetingError24:'権限がありません。',
    meetingError25:'コンテンツが設定されていません。',
    meetingError26:'未入力項目があります。',    
    meetingError27:'着席時のみ使用できます。',
    meetingError28:'起立時のみ使用できます。',
    meetingError29:'ファイルをスクリーン上にドラッグアンドドロップしてください。',
    meetingError30:'メールアドレスを正しく入力してください。',
    meetingError31:'携帯番号を正しく入力してください。',
    meetingError32:'認証コードの送信に失敗しました。もう一度送信してください。',
    meetingError33:'認証コードが間違っています。',
    
    alertMsg01:'設定しました。',
    alertMsg02:'このコンテンツを削除しますか。',
    alertMsg03:'削除されました。',
    alertMsg04:'アバターを変更しました。',
    alertMsg05:'成功しました。',
    alertMsg06:'修正しました。',
    alertMsg07:'ユーザ名を変更しました。',
    alertMsg08:'アップロードが成功しました。',
    alertMsg09:'参加者を退室させました。',
    alertMsg10:'認証コードを発信しました。',
    alertMsg11:'アカウントを作成しました。',
    alertMsg12:'コンテンツに設定しますか。',
    alertMsg13:'既に設定されているコンテンツです。',
    alertMsg14:'リンクを入力してください。',
    alertMsg15:'コンテンツが設定されていません。',
    alertMsg16:'入室権限がありません。',
    alertMsg17:'起立してください。',
    alertMsg18:'このエリアには入れません。',
    alertMsg19:'着席中の移動はできません。',
    alertMsg20:'他のユーザにより画面共有されています。',
    alertMsg21:'認証されました。',
    alertMsg22:'キャンセルしました。',
    alertMsg23:'外部のリンクに移動します。よろしいですか。',
    alertMsg24:'外部のリンクに移動しました。',
    alertMsg25:'ビデオを再生しますか。',
    alertMsg26:'再生します。',
    alertMsg27:'キャンセルしました。',
    alertMsg28:'権限が許可されました。',
    alertMsg29:'参加者の管理',
    alertMsg30:'マイクとカメラ',
    alertMsg31:'すべてのユーザをミュートにする',
    alertMsg32:'すべてのユーザのカメラを停止する',
    alertMsg33:'開く',
    alertMsg34:'しまう',
    alertMsg35:'すべてのユーザをミュートしますか。',
    alertMsg36:'すべてのユーザのカメラを停止しますか。',
    alertMsg37:'このユーザをミュートしますか。',
    alertMsg38:'このユーザのカメラを停止しますか。',
    alertMsg39:'このユーザを退出させますか。',
    alertMsg40:'再度接続中..(3s)',
    alertMsg41:'ゲストコードを入力してください。',
    alertMsg42:'正面スクリーンはPNG,MP4に対応しています。その他のスクリーンはPNGに対応しています。',

    tongdao:'欧莱雅员工专属通道注册',

    cs1:'即将传送至'

}