import SparkMD5 from "spark-md5";
import Bus from "../js/bus";
import AWS from "aws-sdk";
import vueUploadComponent from 'vue-upload-component';
import axios from 'axios'
import fs from 'fs';
import path from "path";


class o2Function {
    constructor() {
        this.client = "",
            this.dir_prefix = "",
            this.t = "",
            this.r = "",
            this.s = ""
    }



    //创建oss client


    // createClient() {
    //     const OSS = require("ali-oss");
    //     axios.post("https://presoft-metaverse.com.cn/AliyunOss/assumeRole.php?").then((res) => {
    //         var data = res.data.body.credentials;
    //         console.log(data);
    //         this.client = new OSS({
    //             // yourRegion填写Bucket所在地域。以华东1（杭州）为例，yourRegion填写为oss-cn-hangzhou。
    //             region: "oss-cn-beijing",
    //             // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
    //             accessKeyId: data.accessKeyId,
    //             accessKeySecret: data.accessKeySecret,
    //             // 从STS服务获取的安全令牌（SecurityToken）。
    //             stsToken: data.securityToken,
    //             // 填写Bucket名称。
    //             bucket: "jp-project",
    //         });
    //         console.log('创建ossclient成功')
    //     });
    // }

    createClient() {
        // const OSS = require("aws-sdk");
        // axios.post("https://presoft-metaverse.com.cn/AliyunOss/assumeRole.php?").then((res) => {
        axios.post("https://control.protest-plus.com/AWS_S3/assumeRole.php").then((res) => {

            // console.log("############:" + res.data.AccessKeyId);
            // console.log("############:" + res.data.SecretAccessKey);
            // console.log("############:" + res.data.SessionToken);

            AWS.config.update({
                region: "ap-northeast-1",
                credentials: new AWS.Credentials(res.data.AccessKeyId, res.data.SecretAccessKey, res.data.SessionToken)
            });

            // Create S3 service object
            this.client = new AWS.S3({});
            console.log('创建S3client成功')
        });
    }

    upload(data) {

        Bus.$emit('progress', 0)
        return new Promise((resolve, reject) => {
            this.getMD5(data, md5 => {
                axios
                    .get(`${document.o2.url}/before_upload.php`, {
                        params: { md5: md5, s: this.s, t: this.t, r: this.r },
                    })
                    .then((res) => {
                        if (res.data.code == 1) {
                            var date = new Date();
                            var nameList = data.name.split('.')
                            var name = nameList[0] + date.getTime() + '.' + nameList[nameList.length - 1];
                            var path = `${res.data.dir_prefix}${name}`;

                            console.log("##################");
                            console.log(data);
                            console.log(data.path);
                            console.log(data.name);
                            console.log(path);
                            this.sliceuploadAWS(data, name, path).then(res => {
                                axios
                                    .get(`${document.o2.url}/after_upload.php`, {
                                        params: { md5: md5, name, name2: data.name, s: this.s, t: this.t, r: this.r },
                                    }).then(res => {
                                        resolve(res.data.data)
                                    })
                            })

                        } else if (res.data.code == 0) {
                            Bus.$emit('progress', 1)
                            resolve(res.data.data)

                        }
                    })
            })
        })
    }

    //分片上传
    async sliceuploadAWS(data, name, path) {
        var that = this;

        var uploadParams = { Bucket: 'meta-res', Key: '', Body: '' };
        uploadParams.Body = data;
        uploadParams.Key = path;
        // var  result = that.client.upload(uploadParams);

        return new Promise((resolve, reject) => {
            try {
                // console.log("$%$%$%$%$%$%$%$%");
                // console.log(ress);
                const uploadoptions = {
                    partSize: 5 * 1024 * 1024,
                    // how many concurrent uploads
                    queueSize: 5
                };
                const result = that.client.upload(uploadParams, uploadoptions, function (err, data) {
                    if (err) {
                        console.log("Error", err);
                    } if (data) {
                        console.log("Upload Success", data.Location);
                    }
                }).on('httpUploadProgress', function(evt) {
                    console.log("Uploaded :: " + parseInt((evt.loaded * 100) / evt.total)+'%');

                    Bus.$emit('progress', (evt.loaded) / evt.total)
                    if(parseInt((evt.loaded) / evt.total) == 1)
                    {
                        console.log("$%$%$%$%$%$%$%$%");
                        console.log(result);
                        resolve(result)                        
                    }
                  });

            } catch (e) {
                console.log(e);
            }
        })
        // console.log("#############simpleUploadAWS");
    }

    //简单上传
    async simpleUpload(data, name, path) {
        var that = this;
        return new Promise((resolve, reject) => {
            try {
                const result = that.client.put(path, data);
                resolve(result)
            } catch (e) {
                console.log(e);
            }
        })
    }


    //分片上传
    async sliceupload(data, name, path) {


        var that = this;

        var page

        if (data.size < 10 * 1024 * 1024) {
            page = 1
        } else if (data.size < 100 * 1024 * 1024) {
            page = 5
        } else {
            page = 10
        }
        return new Promise((resolve, reject) => {
            const options = {

                progress: (p, cpt, res) => {
                    console.log(p);
                    Bus.$emit('progress', p)
                },

                parallel: page,

                partSize: 1024 * 1024,

            };

            // 监听按钮。
            try {
                // 分片上传。
                const res = that.client.multipartUpload(path, data, {
                    ...options,
                });

                resolve(res)
            } catch (err) {
                console.log(err);
            }
        })

    }

    getToken() {
        this.t = Math.floor(new Date().getTime() / 1000);

        this.r = Math.floor(Math.random() * 10000);

        var token = "o2_report2";

        var arr = String(this.t) + String(this.r) + token;

        var s = sha1(arr);

        this.s = md5(s).toUpperCase();
        console.log(this.s);
    }





    //base64编码解码
    encode64(input) {
        var keyStr = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
        var output = "";
        var chr1, chr2, chr3 = "";
        var enc1, enc2, enc3, enc4 = "";
        var i = 0;
        do {
            chr1 = input.charCodeAt(i++);
            chr2 = input.charCodeAt(i++);
            chr3 = input.charCodeAt(i++);
            enc1 = chr1 >> 2;
            enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
            enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
            enc4 = chr3 & 63;
            if (isNaN(chr2)) {
                enc3 = enc4 = 64;
            } else if (isNaN(chr3)) {
                enc4 = 64;
            }
            output = output + keyStr.charAt(enc1) + keyStr.charAt(enc2)
                + keyStr.charAt(enc3) + keyStr.charAt(enc4);
            chr1 = chr2 = chr3 = "";
            enc1 = enc2 = enc3 = enc4 = "";
        } while (i < input.length);
        return output;
    }
    //将Base64编码字符串转换成Ansi编码的字符串
    decode64(input) {
        var keyStr = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
        var output = "";
        var chr1, chr2, chr3 = "";
        var enc1, enc2, enc3, enc4 = "";
        var i = 0;
        if (input.length % 4 != 0) {
            return "";
        }
        var base64test = /[^A-Za-z0-9\+\/\=]/g;
        if (base64test.exec(input)) {
            return "";
        }
        do {
            enc1 = keyStr.indexOf(input.charAt(i++));
            enc2 = keyStr.indexOf(input.charAt(i++));
            enc3 = keyStr.indexOf(input.charAt(i++));
            enc4 = keyStr.indexOf(input.charAt(i++));
            chr1 = (enc1 << 2) | (enc2 >> 4);
            chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
            chr3 = ((enc3 & 3) << 6) | enc4;
            output = output + String.fromCharCode(chr1);
            if (enc3 != 64) {
                output += String.fromCharCode(chr2);
            }
            if (enc4 != 64) {
                output += String.fromCharCode(chr3);
            }
            chr1 = chr2 = chr3 = "";
            enc1 = enc2 = enc3 = enc4 = "";
        } while (i < input.length);
        return output;
    }
    utf16to8(str) {

        var out, i, len, c;
        out = "";
        len = str.length;
        for (i = 0; i < len; i++) {
            c = str.charCodeAt(i);
            if ((c >= 0x0001) && (c <= 0x007F)) {
                out += str.charAt(i);
            } else if (c > 0x07FF) {
                out += String.fromCharCode(0xE0 | ((c >> 12) & 0x0F));
                out += String.fromCharCode(0x80 | ((c >> 6) & 0x3F));
                out += String.fromCharCode(0x80 | ((c >> 0) & 0x3F));
            } else {
                out += String.fromCharCode(0xC0 | ((c >> 6) & 0x1F));
                out += String.fromCharCode(0x80 | ((c >> 0) & 0x3F));
            }
        }
        return out;
    }
    utf8to16(str) {

        var out, i, len, c;
        var char2, char3;
        out = "";
        len = str.length;
        i = 0;
        while (i < len) {
            c = str.charCodeAt(i++);
            switch (c >> 4) {
                case 0: case 1: case 2: case 3: case 4: case 5: case 6: case 7:
                    // 0xxxxxxx
                    out += str.charAt(i - 1);
                    break;
                case 12: case 13:
                    // 110x xxxx 10xx xxxx
                    char2 = str.charCodeAt(i++);
                    out += String.fromCharCode(((c & 0x1F) << 6) | (char2 & 0x3F));
                    break;
                case 14:
                    // 1110 xxxx 10xx xxxx 10xx xxxx
                    char2 = str.charCodeAt(i++);
                    char3 = str.charCodeAt(i++);
                    out += String.fromCharCode(((c & 0x0F) << 12) |
                        ((char2 & 0x3F) << 6) |
                        ((char3 & 0x3F) << 0));
                    break;
            }
        }
        return out;
    }
    //开启拖拽监听
    createDrag() {
        document.addEventListener('dragover', this.FileDragHover, false)
        //监听文件拖拽移出
        document.addEventListener('dragleave', this.FileDragLeave, false)
        //监听文件拖拽放下(必须)
        document.addEventListener('drop', this.Drop, false)
        document.drag = this
    }
    //解除拖拽监听
    destroyed() {
        document.removeEventListener('dragover', this.FileDragHover, false)
        //监听文件拖拽移出
        document.removeEventListener('dragleave', this.FileDragLeave, false)
        //监听文件拖拽放下(必须)
        document.removeEventListener('drop', this.Drop, false)
    }

    //处理文件拖入事件
    FileDragHover(e) {
        e.stopPropagation();
        e.preventDefault();
        //添加类名修改样式
        e.target.classList.add("dropBoxHover")
        // e.target.innerText = "松开鼠标上传"
    }
    //处理拖拽离开事件
    FileDragLeave(e) {
        e.stopPropagation();
        e.preventDefault();
        //移除类名修改样式
        e.target.classList.remove("dropBoxHover")
        // e.target.innerText = "拖拽文件上传"
    }
    //处理文件拖拽放下事件

    async Drop(e) {


        //阻止事件冒泡
        e.stopPropagation();
        //阻止事件的默认行为
        e.preventDefault();
        //储存获取到的文件列表
        let fileList = [];
        let DirectoryEntryList = [];

        //清除样式
        e.target.classList.remove("dropBoxHover")
        if (e.dataTransfer.items) {
            // 拖拽对象列表转换成数组
            let items = new Array(...e.dataTransfer.items);
            // 获得DirectoryEntry对象列表
            for (let index = 0; index < items.length; index++) {
                let e = items[index];
                let item = null;
                //兼容不同内核的浏览器
                if (e.webkitGetAsEntry) {
                    item = e.webkitGetAsEntry();
                } else if (e.getAsEntry) {
                    item = e.getAsEntry();
                } else {
                    this.$alert("浏览器不支持拖拽上传", "提示");
                    return;
                }
                DirectoryEntryList.push(item);
            }
            if (DirectoryEntryList.length > 0) {
                for (let index = 0; index < DirectoryEntryList.length; index++) {
                    let item = DirectoryEntryList[index];
                    if (item) {
                        //获取文件夹目录
                        let FileTree = await document.drag.getFileTree(item);
                        // 拿到目录下的所有文件
                        if (Array.isArray(FileTree)) {
                            //展平文件夹
                            flattenArray(FileTree, fileList);
                        } else {
                            //方便后续处理，单文件时也包装成数组
                            fileList.push(FileTree);
                        }
                    }
                }
            }
        }

        var obj = {
            x: e.offsetX,
            y: e.offsetY,
            file: fileList[0]
        }
        // var chatroom = document.getElementById('chatroom')
        //   console  this.checkIn(chatroom)
        Bus.$emit("drag", obj)
    }

    checkIn(obj) {
        var x = Number(window.event.clientX) // 鼠标相对屏幕横坐标
        var y = Number(window.event.clientY) // 鼠标相对屏幕纵坐标

        var div_x = Number(obj.getBoundingClientRect().left) // obj相对屏幕的横坐标
        var div_x_width = Number(
            obj.getBoundingClientRect().left + obj.clientWidth
        ) // obj相对屏幕的横坐标+width

        var div_y = Number(obj.getBoundingClientRect().top) // obj相对屏幕的纵坐标
        var div_y_height = Number(
            obj.getBoundingClientRect().top + obj.clientHeight
        ) // obj相对屏幕的纵坐标+height

        if (x > div_x && x < div_x_width && y > div_y && y < div_y_height) {
            return true
        } else {
            return false
        }
    }

    /**
    * 获取文件
    */
    fileSync(item) {
        return new Promise((resolve, reject) => {
            item.file(res => {
                resolve(res);
            });
        });
    }
    //读取文件夹下的文件
    readEntriesSync(dirReader) {
        return new Promise((rel, rej) => {
            dirReader.readEntries(res => {
                rel(res);
            });
        });
    }
    /**
     * 获取文件目录结构树
     *
     */
    async getFileTree(item) {
        let that = this;
        var path = item.fullPath || "";
        let dir = new Array();
        if (item.isFile) {
            let resFile = await this.fileSync(item);
            resFile.path = path;
            return resFile;
            // item为文件夹时
        } else if (item.isDirectory) {
            var dirReader = item.createReader();
            let entries = await that.readEntriesSync(dirReader);
            for (let i = 0; i < entries.length; i++) {
                let proItem = await that.getFileTree(entries[i]);
                dir.push(proItem);
            }
            return dir;
        }
    }


    // 文件转MD5
    getMD5(file, callBack) {
        /*
         *     file 选取的文件
         *     callBack 回调函数可以返回获取的MD5
         */
        let fileReader = new FileReader(),
            blobSlice =
                File.prototype.mozSlice || File.prototype.webkitSlice || File.prototype.slice,
            chunkSize = 2097152,
            // read in chunks of 2MB
            chunks = Math.ceil(file.size / chunkSize),
            currentChunk = 0,
            spark = new SparkMD5();
        fileReader.onload = function (e) {
            spark.appendBinary(e.target.result); // append binary string
            currentChunk++;
            if (currentChunk < chunks) {
                loadNext();
            } else {
                callBack(spark.end());
            }
        };
        function loadNext() {
            let start = currentChunk * chunkSize,
                end = start + chunkSize >= file.size ? file.size : start + chunkSize;
            fileReader.readAsBinaryString(blobSlice.call(file, start, end));
        }
        loadNext();
    }


}



export { o2Function };