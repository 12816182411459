import * as THREE from 'three/build/three.module';
class player {
  constructor() {
    console.log("new player");
    this.target = new THREE.Vector3();
    this.id = 0;
    this.name = "";
    this.sex = 0;
    this.auth = 1;
    this.obj;
    this.accInfo = [0,0,0,1];
    this.dicBone = {};
    this.arrBone = [];
    this.mixer;
    this.modelIdx = 0;
    this.isNPC = false;
    this.bodyMat;
    this.bodyColor= "#F66C47";
    this.hairMat;
    this.hairColor= "#191919";
    this.arrAccObj = [];
    this.boneInverses = [];
    this.onStateChg = [];
    this.o2 = document.o2;
  }
  static arrPrebAcc = [];
  static onModelLoad = [];
  static arrAccName = [
    "Accessory",
    "Hair",
    "Hat",
    "Suit"
  ];
  static accNum = player.arrAccName.length;
  static modelPreb = {};
  static modelUrl = [
    [
      "",
      "https://meta-res.s3.ap-northeast-1.amazonaws.com/models/Male_NPC_01.zip",
      "https://meta-res.s3.ap-northeast-1.amazonaws.com/models/Male_NPC_02.zip",
      "https://meta-res.s3.ap-northeast-1.amazonaws.com/models/Male_NPC_03.zip",
      "https://meta-res.s3.ap-northeast-1.amazonaws.com/models/Male_NPC_04.zip",
      "https://meta-res.s3.ap-northeast-1.amazonaws.com/models/Male_NPC_05.zip",
      "https://meta-res.s3.ap-northeast-1.amazonaws.com/models/Female_NPC_01.zip",
      "https://meta-res.s3.ap-northeast-1.amazonaws.com/models/Female_NPC_02.zip",
      "https://meta-res.s3.ap-northeast-1.amazonaws.com/models/Female_NPC_03.zip",
      "https://meta-res.s3.ap-northeast-1.amazonaws.com/models/Female_NPC_04.zip",
      "https://meta-res.s3.ap-northeast-1.amazonaws.com/models/Female_NPC_05.zip"
    ],
    [
      "",
      "https://meta-res.s3.ap-northeast-1.amazonaws.com/models/Male_01.zip",
      "https://meta-res.s3.ap-northeast-1.amazonaws.com/models/Male_02.zip",
      "https://meta-res.s3.ap-northeast-1.amazonaws.com/models/Male_03.zip",
      "https://meta-res.s3.ap-northeast-1.amazonaws.com/models/Male_04.zip",
      "https://meta-res.s3.ap-northeast-1.amazonaws.com/models/Male_05.zip",
      "https://meta-res.s3.ap-northeast-1.amazonaws.com/models/Male_06.zip"
    ], 
    [
      "",
      "https://meta-res.s3.ap-northeast-1.amazonaws.com/models/Female_01.zip",
      "https://meta-res.s3.ap-northeast-1.amazonaws.com/models/Female_02.zip",
      "https://meta-res.s3.ap-northeast-1.amazonaws.com/models/Female_03.zip",
      "https://meta-res.s3.ap-northeast-1.amazonaws.com/models/Female_04.zip",
      "https://meta-res.s3.ap-northeast-1.amazonaws.com/models/Female_05.zip",
      "https://meta-res.s3.ap-northeast-1.amazonaws.com/models/Female_06.zip"
    ],
  ];
  static tempUrl = "https://meta-res.s3.ap-northeast-1.amazonaws.com/models/%E5%9D%90%E5%A7%BF.zip";
  static handTexPath = require("./assets/newImg/举手.png");
  static handMat;
  static suitMaxNum = 5;

  initPlayer(info, action) {
    this.setPlayerInfo(info);
    this.setModel(this.modelIdx,()=>{
      //this.combineMesh();
      //this.boneInverses = this.accMesh.skeleton.boneInverses;
      //console.log(this.boneInverses);
      if(!this.isNPC){
        this.initAcc();
        this.setHairColor();
        this.setBodyColor();
      }
      this.obj.name = this.name;
      this.namePanel = player.add_lable_to_char(this.obj,this.name,this.auth);
      this.handPanel = player.add_hand_to_char(this.obj);
      this.handPanel.visible = false;
      console.log(this);
      action(this);
    });
  }
  resetPlayer(info){
    this.isTemp = false;
    if(this.setPlayerInfo(info)){
      if(this.arrAccObj && this.arrAccObj.length > 0){
        this.arrAccObj = [];
      }
      this.setModel(this.modelIdx,()=>{
        if(!this.isNPC)this.initAcc();
        this.o2.scene.add(this.obj);
        let clipName = "";
        if(this.state == "sit"){
          clipName = "标准坐";
        }
        else{
          clipName = this.state;
        }
        this.playClip(clipName,true);
        //this.o2.play_skeleton_clip(this.obj,"idle",true);
        console.log(this);
      });
    }
    else if(!this.isNPC){
      this.initAcc();
    }
  }
  setPlayerInfo(info){
    let isChgModel= false;
    if(info){
      if(info.auth){
        this.auth = parseInt(info.auth);
      }
      if(this.auth > 3){
        this.isNPC = true;
      }
      if(info.name){
        this.name = info.name;
      }
      if(info.id != undefined){
        this.id = parseInt(info.id);
      }
      if(info.sex != undefined){
        this.sex = info.sex;
      }
      if(info.accInfo){
        let arrStr = info.accInfo.split("-");
        if(arrStr && arrStr.length == player.arrAccName.length){
          for (let i = 0; i < arrStr.length; i++) {
            if(i < this.accInfo.length){
              this.accInfo[i] = parseInt(arrStr[i]);
            }
          }
        }
      }
      if(info.hairColor){
        this.setHairColor(info.hairColor);
        // this.hairColor = info.hairColor;
        // if(this.hairMat){
        //   this.hairMat.color.set(this.hairColor);
        // }
      }
      if(info.bodyColor){
        this.setBodyColor(info.bodyColor);
        // this.bodyColor = info.bodyColor;
        // if(this.bodyMat){
        //   this.bodyMat.color.set(this.bodyColor);
        // }
      }
    }
    let type = player.arrAccName.indexOf("Suit");
    let modelIdx = this.accInfo[type];
    if(!this.isNPC){
      for (let i = 0; i < this.sex; i++) {
        if(i < player.modelUrl.length){
          modelIdx += player.modelUrl[i].length;
        }
      }
    }
    isChgModel = (this.modelIdx != modelIdx);
    this.modelIdx = modelIdx;
    return isChgModel;
  }
  initAcc() {
    if (player.arrPrebAcc[this.modelIdx]) {
      let prebAcc = player.arrPrebAcc[this.modelIdx];
      if (prebAcc && player.arrAccName) {
        for (let i = 0; i < player.arrAccName.length; i++) {
          let name = player.arrAccName[i];
          if(name == "Suit")break;
          if (prebAcc[name] && prebAcc[name].length) {
            let idx = this.accInfo[i];
            //let len = prebAcc[name].length;
            //this.accInfo[i] = 0;
            this.setAcc(i, idx);
          }
        }
      }
    }
  }

  initPrebAcc(modelIdx, obj) {
    if (player.arrPrebAcc[modelIdx]) return;
    player.arrPrebAcc[modelIdx] = {};
    let arrAccObj = player.arrPrebAcc[modelIdx];
    if (obj) {
      //console.log(this.obj);
      obj.children.forEach(e => {
        let arrStr = e.name.split("_");
        if (arrStr && arrStr.length >= 2) {
          for (let i = 0; i < player.arrAccName.length; i++) {
            const name = player.arrAccName[i];
            if(name == "Suit")break;
            if (arrStr[0] == name) {
              if (arrAccObj[name] == undefined) {
                arrAccObj[name] = [];
              }
              let idx = Number.parseInt(arrStr[1]);
              // if(i < this.accInfo.length){
              //     e.visible = (idx == this.accInfo[i]);
              // }
              if (arrAccObj[name][idx] == undefined) {
                arrAccObj[name][idx] = {};
                arrAccObj[name][idx].idx = idx;
                arrAccObj[name][idx].arrObj = [];
              }
              arrAccObj[name][idx].arrObj.push(e);
              break;
            }
          }
        }
      });
    }
  }
  clonePreb() {
    if (player.modelPreb[this.modelIdx]) {
      let pos;
      let rot;
      if(this.obj){
        pos = this.obj.position;
        rot = this.obj.rotation;
        if(this.name){
          this.obj.remove(this.namePanel);
        }
        this.o2.scene.remove(this.obj);
        this.obj = null;
      }
      this.obj = this.cloneObj(player.modelPreb[this.modelIdx].obj);
      this.mixer = new THREE.AnimationMixer(this.obj);
      this.mixer.addEventListener("finished", e => {
        if(this.curAction && this.lastAction && this.curAction != this.lastAction){
          this.curAction.time = 0;
          this.curAction.enabled = true;
          this.curAction.play();
          this.lastAction.enabled = true;
          this.curAction.crossFadeTo(this.lastAction,0.2);
          this.lastAction.play();
          this.curAction = this.lastAction;
        }
      });
      this.clips = player.modelPreb[this.modelIdx].clips;
      this.o2.skeletons.push({ name: this.name, obj: this.obj, clips: this.clips, mixer: this.mixer });
      this.obj.traverse(child => {
        if (child.name.indexOf("Body") != -1) {
          if(this.bodyMat == undefined){
            //this.bodyMat = new THREE.MeshBasicMaterial({map:child.material.map});
            this.bodyMat = child.material.clone();
          }
          child.material = this.bodyMat;
        }
        else if (child.name.indexOf("Shadow") != -1) {
          child.visible = true;
          child.material.transparent = true;
        }
      });
      if(pos && rot && this.namePanel){
          this.obj.add(this.namePanel);
          this.obj.position.set(pos.x,pos.y,pos.z);
          this.obj.rotation.set(rot.x,rot.y,rot.z);
      }
      // this.initAcc(accInfo);
      // console.log(this.obj);
      // if (action) action(this);
    }
  }
  
  setModel(modelIdx, action){
    if (player.modelPreb[modelIdx]) {
      //this.destroyObj();
      let preb = player.modelPreb[modelIdx];
      if (!preb.obj) {
        player.onModelLoad.push((idx) => {
          if (modelIdx == idx) {
            this.clonePreb();
            if(action)action();
          }
        });
      }
      else{
        this.clonePreb();
        if(action)action();
      }
    }
    else {
      player.modelPreb[modelIdx] = {};
      let url;
      let idx = 0;
      if(this.sex < player.modelUrl.length){
        idx = modelIdx;
        let type = this.isNPC?0:this.sex;

        for (let i = 0; i < type; i++) {
          idx -=  player.modelUrl[i].length;
        }
        url = player.modelUrl[type][idx];
      }
      if(!url)return;
      let promise = this.o2.import_object_url(url);
      promise.then((obj3) => {
        obj3.children.forEach(e => {
          if(e.material){
            e.material.vertexColors = THREE.NoColors;
            //e.material.color.multiplyScalar(0.6);
          }
          if(e.name.indexOf("Cloth") != -1){
            e.material.color.multiplyScalar(0.8);
          }
        });
        player.modelPreb[modelIdx].obj = obj3;
        if(!this.isNPC)this.initPrebAcc(modelIdx, obj3);
        //console.log(player.arrPrebAcc);
        //console.log(obj3);
        let arr = this.o2.skeletons;
        //player.modelPreb[sex].clips = arr[0].clips;
        if (arr) {
          for (let i = 0; i < arr.length; i++) {
            let e = arr[i];
            if (e.obj == obj3) {
              player.modelPreb[modelIdx].clips = e.clips;
              break;
            }
          }
        }
        this.clonePreb();
        if(action)action();
        player.onModelLoad.forEach(e => {
          e(modelIdx);
        });
      });
    }
  }
  setState(clipName){
    let state;
    if(clipName == "标准坐"){
      state = "sit";
      //this.state = "sit";
    }
    else if(clipName == "idle" || clipName == "walk"){
      state = clipName;
      //this.state = clipName;
    }
    if(state && state != this.state){
      this.state = state;
      if(this.onStateChg && this.onStateChg.length > 0){
        this.onStateChg.forEach(e=>{e(state)});
      }
    }
    if(this.state != "sit" && this.handPanel){
      this.handPanel.visible = false;
    }
  }
  setSex(sex){
    if(sex != this.sex){
      this.sex = sex;
      //let type = player.arrAccName.indexOf("Suit");
      this.resetPlayer();
    }
    
  }
  setBodyColor(rgb) {
    if(this.isNPC)return;
    if(rgb != undefined)this.bodyColor = rgb;
    if (this.bodyMat) {
      this.bodyMat.color.set(this.bodyColor);
      this.bodyMat.color.multiplyScalar(0.8);
    }
  }
  setHairColor(rgb){
    if(this.isNPC)return;
    if(rgb != undefined)this.hairColor = rgb;
    if(this.hairMat){
      this.hairMat.color.set(this.hairColor);
      this.hairMat.color.multiplyScalar(0.8);
    }
  }
  setAcc(type, idx) {
    if (type >= 0 && type < player.arrAccName.length) {
      let name = player.arrAccName[type];
      let prebAcc = player.arrPrebAcc[this.modelIdx];
      //let curIdx = this.accInfo[type];
      if (prebAcc && prebAcc[name]) {
        if (prebAcc[name].length > idx) {
          let accs = prebAcc[name];
          if (accs) {
            if(player.arrAccName[type] == "Hat"){
              let hairType = player.arrAccName.indexOf("Hair");
              if(this.accInfo[hairType] > 0&& idx > 0){
                this.hairIdx = this.accInfo[hairType];
                this.setAcc(hairType,0);
              }
              else if(this.hairIdx > 0 && idx == 0){
                  this.accInfo[type] = idx;
                  this.setAcc(hairType,this.hairIdx);
              }
            }
            else if(player.arrAccName[type] == "Hair"){
              let hatType = player.arrAccName.indexOf("Hat");
              if(this.accInfo[hatType] > 0 && idx > 0){
                this.hairIdx = idx;
                return;
              }
            }
            let acc = this.arrAccObj[type];
            if((acc && (acc.idx != idx || acc.modelIdx != this.modelIdx)) || !acc){
              if (type < this.accInfo.length && acc) {
                let arr = acc.arrObj;
                if (arr && arr.length > 0) {
                  arr.forEach(e => {
                    this.obj.remove(e);
                  });
                }
              }
              if (accs.length > idx && idx >= 0) {
                let newAcc = this.cloneAcc(type, idx);
                this.arrAccObj[type] = newAcc;
                this.accInfo[type] = idx;
              }
            }  
          }
        }
      }
      else if(name == "Suit"){
        let curIdx = this.accInfo[type];
        if(curIdx != idx){
          this.accInfo[type] = idx;
          this.resetPlayer();
        }
      }
    }
  }
  cloneAcc(type, idx) {
    if (player.arrPrebAcc[this.modelIdx]) {
      let prebAcc = player.arrPrebAcc[this.modelIdx];
      if (type < player.arrAccName.length && type >= 0) {
        let newAcc;
        if(!this.arrAccObj[type]){
          newAcc = {};
        }
        else{
          newAcc = this.arrAccObj[type];
        }
        newAcc.type = type;
        newAcc.idx = idx;
        newAcc.modelIdx = this.modelIdx;
        newAcc.arrObj = [];
        let name = player.arrAccName[type];
        if (prebAcc[name] && prebAcc[name].length > idx && idx >= 0) {
          let acc = prebAcc[name][idx];
          if (acc && acc.arrObj.length) {
            acc.arrObj.forEach(e => {
              let obj = e.clone();
              if(obj.name.indexOf("Hair") != -1){
                if(this.hairMat == undefined){
                  //this.hairMat = new THREE.MeshBasicMaterial({map:obj.material.map});
                  this.hairMat = obj.material.clone();
                }
                obj.material = this.hairMat;
                this.setHairColor();
              }
              if (e.type == "SkinnedMesh") {
                let newArr = [];
                e.skeleton.bones.forEach(item => {
                  newArr.push(this.dicBone[item.name]);
                });
                let arr = e.skeleton.boneInverses;
                obj.bind(new THREE.Skeleton(newArr, arr), e.bindMatrix);
              }
              this.obj.add(obj);
              newAcc.arrObj.push(obj);
            });
            return newAcc;
          }
        }
      }
    }
  }
  getPlayerInfo() {
    if (!this.playerInfo) {
      this.playerInfo = {};
    }
    this.playerInfo.id = this.id;
    this.playerInfo.name = this.name;
    this.playerInfo.sex = this.sex;
    this.playerInfo.accInfo = "";
    this.playerInfo.auth = this.auth;
   for (let i = 0; i < this.accInfo.length; i++) {
     if(i<this.accInfo.length - 1){
       this.playerInfo.accInfo += this.accInfo[i] + "-";
     }
     else{
      this.playerInfo.accInfo += this.accInfo[i];
     }
   }
  //this.playerInfo.accInfo = this.accInfo;
  this.playerInfo.bodyColor = this.bodyColor;
  this.playerInfo.hairColor = this.hairColor;
  return this.playerInfo;
  }
  getIdx(idx){
    let temp =idx;
    for (let i = 0; i < this.sex; i++) {
      if(this.sex < player.modelUrl.length){
        temp += player.modelUrl[this.sex].length;
      }
    }
    return temp;
  } 

  getBoneArray(bone, bonearr) {
    for (let i = 0; i < bone.children.length; i++) {
      bonearr[bone.children[i].name] = bone.children[i];
      this.getBoneArray(bone.children[i], bonearr);
    }
  }
  cloneObj(preb) {
    let obj = preb.clone(false);
    for (let i = 0; i < preb.children.length; i++) {
      if (preb.children[i].type == "Bone") {
        let rootBone = preb.children[i].clone();
        rootBone.visible = false;
        this.dicBone[rootBone.name] = rootBone;
        obj.add(rootBone);
        this.getBoneArray(rootBone, this.dicBone);
        for (const key in this.dicBone) {
          this.arrBone.push(this.dicBone[key]);
        }
        break;
      }
    }
    for (let j = 0; j < preb.children.length; j++) {
      let isAcc = false;
      if(!this.isNPC){
        for (let k = 0; k < player.arrAccName.length; k++) {
          let n = player.arrAccName[k];
          isAcc = (preb.children[j].name.indexOf(n) != -1)
          //||(preb.children[j].name.indexOf("Eye") != -1);
          if (isAcc) break;
        }
      }
      if (!isAcc) {
        if (preb.children[j].type == "SkinnedMesh") {

          let skinmesh = preb.children[j].clone();
          let newArr = [];
          preb.children[j].skeleton.bones.forEach(item => {
            newArr.push(this.dicBone[item.name]);
          });
          let arr = preb.children[j].skeleton.boneInverses;
          skinmesh.bind(new THREE.Skeleton(newArr, arr),
            preb.children[j].bindMatrix);
          obj.add(skinmesh);
        }
        else if (preb.children[j].type != "Bone") {
          let c = preb.children[j].clone();
          obj.add(c);
        }
      }
    }
    return obj;
  }
  combineMesh(){
    if(this.obj){
      if(this.accMesh){
        this.accMesh.geometry.dispose();
        this.obj.remove(this.accMesh);
        this.accMesh = null;
      }
      let vertices = [];
      let normals = [];
      let skinIndices = [];
      let skinWeights = [];
      // if(!this.boneInverses)
      //   this.boneInverses = [];
      let arrUV = [];
      let arrUV2 = [];
      let matrices = [];
      //console.log(this.arrBone);
      //let mat;
      // let temp = this.obj.matrix.clone();
      // this.obj.matrix = this.obj.matrix.identity ();
      console.log(this.obj.matrix);
      let pos = new THREE.Vector3();
      this.obj.children.forEach(c => {
          if(c.type == "SkinnedMesh" 
          && c.name.indexOf("Body") == -1
          && c.name.indexOf("Eye") == -1
          && c.name.indexOf("Hair") == -1){
            if(!this.combineMat){
              this.combineMat = new THREE.MeshBasicMaterial({map:c.material.map});
            }
            let poses = c.geometry.attributes.position.array;
            for (let i = 0; i < poses.length; i+=3) {
              pos.set(poses[i],poses[i+1],poses[i+2]);
              pos.applyMatrix4(c.matrix);
              vertices.push(pos.x);
              vertices.push(pos.y);
              vertices.push(pos.z);
            }
            let arrNormal = c.geometry.attributes.normal.array;
            arrNormal.forEach(e => {normals.push(e)});
            let uv = c.geometry.attributes.uv.array;
            uv.forEach(e => {
              arrUV.push(e);
              arrUV2.push(0);
            });
            // let uv2 = c.geometry.attributes.uv2.array;
            // uv2.forEach(e => {arrUV2.push(e)});
            //poses.forEach(e=>{vertices.push(e)});
            let bs = c.skeleton.bones;
            let idxs = c.geometry.attributes.skinIndex.array;
            idxs.forEach(e=>{
              if(e < bs.length){
                let bone = bs[e];
                let idx = this.arrBone.indexOf(bone);
                skinIndices.push(idx);
              }
            });
            let wgts = c.geometry.attributes.skinWeight.array;
            wgts.forEach(e=>{skinWeights.push(e)});
            let arrMatrix = c.skeleton.boneMatrices;
            arrMatrix.forEach(e => {
              matrices.push(e);
            });
            // let bms = c.bindMatrix;
            // bms.forEach(e=>{bindMatrix.push(e)});
            c.visible = false;
            c.material = this.combineMat;
            
          }
      });
      let geometry = new THREE.BufferGeometry();
      geometry.setAttribute("position",new THREE.Float32BufferAttribute(vertices,3));
      geometry.setAttribute("normal",new THREE.Float32BufferAttribute(normals,3));
      geometry.setAttribute( 'skinIndex', new THREE.Uint16BufferAttribute(skinIndices, 4 ) );
      geometry.setAttribute( 'skinWeight', new THREE.Float32BufferAttribute(skinWeights, 4 ) );
      geometry.setAttribute( 'uv', new THREE.Float32BufferAttribute(arrUV, 2 ) );
      geometry.setAttribute( 'uv2', new THREE.Float32BufferAttribute(arrUV2, 2 ) );
      let mesh = new THREE.SkinnedMesh(geometry,this.combineMat);
      mesh.bind(new THREE.Skeleton(this.arrBone));
      if(this.boneInverses && this.boneInverses.length == this.arrBone.length){
        mesh.skeleton.boneInverses = this.boneInverses;
      }
      this.accMesh = mesh;
      mesh.name = "combineMesh";
      this.obj.add(mesh);
      console.log(mesh);
      //this.obj.matrix = temp;
      //this.o2.play_skeleton_clip(this.obj,"ide",true);
    }
  }
  static add_lable_to_char(object, name,auth) {
    let canvas = document.createElement("canvas");
    canvas.width = 160;
    canvas.height = 32;
    const drawingContext = canvas.getContext("2d");
    drawingContext.fillStyle = "#000000";
    drawingContext.globalAlpha = 0.1;
    drawingContext.fillRect(0, 0, 160, 32);
    drawingContext.globalAlpha = 1;
    if(auth == 4){
      drawingContext.fillStyle = "#00FF00";
    }
    else if(auth == 5){
      drawingContext.fillStyle = "#FF9300";
    }
    else{
      drawingContext.fillStyle = "#ffffff";
    }
    drawingContext.font = "24px Georgia";
    drawingContext.textAlign = "center";
    drawingContext.fillText(name, 80, 24);

    let map = new THREE.CanvasTexture(canvas);
    let sprite = new THREE.Sprite(
      new THREE.SpriteMaterial({ map: map, color: "#ffffff" })
    );
    sprite.position.set(0, 2000, 0);
    sprite.scale.set(600, 120, 1);
    sprite.material.depthWrite = false;
    object.add(sprite);
    return sprite;
  }
  static add_hand_to_char(obj){
    let sprite = new THREE.Sprite();
    sprite.scale.set(600,600,1);
    obj.add(sprite);
    sprite.position.set(0,2500,0);
    if(player.handMat == undefined){
      player.handMat = new THREE.SpriteMaterial();
      player.handMat.depthWrite = false;
      player.handMat.depthTest = false;
      let texLoader = new THREE.TextureLoader();
      texLoader.load(player.handTexPath,tex=>{
        player.handMat.map = tex;
        sprite.material = player.handMat;
      });
    }
    else{
      sprite.material = player.handMat;
    }
    return sprite;
  }
  static createTempPlayer(id,name,action){
    player.loadTempModel(obj=>{
      let p = new player();
      p.state = "sit";
      p.id = id;
      p.name = name;
      p.isTemp = true;
      p.obj = obj.clone();
      p.handPanel = player.add_hand_to_char(p.obj);
      p.handPanel.visible = false
      p.namePanel = player.add_lable_to_char(p.obj,name);
      if(action)action(p);
    });
  }
  static loadTempModel(action){
    if(!player.tempModel){
      let promise = document.o2.import_object_url(player.tempUrl);
      promise.then(obj=>{
        player.tempModel = obj;
        if(action)action(obj);
      });
    }
    else{
      if(action)action(player.tempModel);
    }
  }
  playClip(name,loop,clamp){
    let clip = this.clips[name];
    if (this.mixer && clip) {
        this.setState(name);
        let action = this.mixer.clipAction(clip, this.obj);
        if (this.curAction) {
            //this.curAction.enabled = true;
            //this.curAction.weight = 0;
            this.curAction.crossFadeTo(action, 0.2);
            if(this.curAction.loop == THREE.LoopRepeat){
              this.lastAction = this.curAction;
            }
        }
        action.enabled = true;
        this.curAction = action;
        // AnimationAction.timeScale = 1; //默认1，可以调节播放速度
        if (loop) {
            action.loop = THREE.LoopRepeat; //不循环播放
        }
        else {
          action.time = 0;
          action.loop = THREE.LoopOnce;
          action.clampWhenFinished=clamp;
        }
        action.play();//播放动画
    }
  }
  destroyObj(){
    if(this.obj){
      this.obj.children.forEach(c => {
        if(c.name.indexOf("Body") != -1){
          c.material.dispose();
        }
        else if(c.name.indexOf("Hair") != -1){
          c.material.dispose();
        }
        else if(c.name.indexOf("combineMesh") != -1){
          c.geometry.dispose();
        }
      });
      this.o2.scene.remove(this.obj);
      this.obj = null;
    }
  }
  destroy(){
    // if(this.bodyMat){
    //   this.bodyMat.dispose();
    // }
    // if(this.hairMat){
    //   this.hairMat.dispose();
    // }
    if(this.namePanel){
      this.namePanel.material.dispose();
    }
    this.o2.scene.remove(this.obj);
    this.obj = null;
    //this.destroyObj();
  }
}
export { player }
