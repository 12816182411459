import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  // mode:'history',
  routes: [

    {
      path: '/',
      redirect: '/login'
    },

    {
      path: '/index',
      component: () => import(/* webpackChunkName: "login" */ './view/index.vue'),
      meta: { title: '首页' },
      name: 'index'
    },

    {
      path: '/wxLogin',
      component: () => import(/* webpackChunkName: "login" */ './view/wxLogin.vue'),
      meta: { title: '首页' },
      name: 'wxLogin'
    },

    {
      path: '/mobileIndex',
      component: () => import(/* webpackChunkName: "login" */ './view/mobileIndex.vue'),
      meta: { title: '首页' },
      name: 'mobileIndex'
    },
    
    {
      path: '/mobileLogin',
      component: () => import(/* webpackChunkName: "login" */ './view/mobileLogin.vue'),
      meta: { title: '登录' },
      name: 'mobileLogin'
    },

    {
      path: '/login',
      component: () => import(/* webpackChunkName: "login" */ './view/login.vue'),
      meta: { title: '登录' },
      name: 'login'
    },

    {
      path: '/management',
      component: () => import(/* webpackChunkName: "management" */ './view/login1.vue'),
      meta: { title: '登录' },
      name: 'management'
    },

    {
      path: '/mobileTest',
      component: () => import(/* webpackChunkName: "login" */ './view/mobileTest.vue'),
      meta: { title: '检测' },
      name: 'mobileTest'
    },

    {
      path: '/mobileIndex',
      component: () => import(/* webpackChunkName: "login" */ './view/mobileIndex.vue'),
      meta: { title: '首页' },
      name: 'mobileIndex'
    }

    // {
    //   path: '/mobileIndex',
    //   component: () => import(/* webpackChunkName: "login" */ './view/login.vue'),
    //   meta: { title: '登录' },
    //   name: 'login'
    // },
  ]
})