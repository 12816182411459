export const lang = {

    title:'Oreal exhibition hall',
    account: 'Account',
    password: 'Password',
    nickname: 'Nickname',
    accountInfo: 'Please enter your mobile number / email',
    passwordInfo: 'please enter password',

    passwordInfo1: 'Please enter the password again',

    passwordInfo2: 'The two input passwords are inconsistent',
    nicknameInfo: 'Please enter your nickname',
    login: 'Login',
    customer: 'Customer',
    register: 'Register',
    language: 'Language',
    zh: 'Chinese',
    en: 'English',
    loginError1: 'Account password cannot be empty',
    loginError2: 'Password error',
    loginError3: 'Account does not exist',

    loginError4: 'Do not login repeatedly',
    loginError5: 'Account is not a mobile phone number / email',
    
    loginSuccess: 'Login success',
    function1: "Direct you to",
    function2: "Avatar selection",
    function3: "Say hi",
    function4: 'User manage',
    logOut: 'Log out',
    logOutInfo: 'Double check Log out?',
    close: 'Close',
    online: 'Online',
    AccName: [
        "Sex",
        "Accessory",
        "Hair",
        "Hat",
        "Suit",
        "Hair color",
        "Skin color"
    ],
    sendError: 'The content you sent cannot be empty',
    sendInfo: 'Please enter content',
    send: 'Send',
    peopleOnline: 'People online',
    world: 'Chat box',
    clientError: "You haven't entered the conference room yet, so you don't have permission",
    videoError: "You don't have a camera",
    micError: `You don't have a microphone, failed to enter the room`,
    saveInfo: 'Saved successfully',
    shareError: 'Someone has shared the screen at present',
    roam1:'Main Stage',
    roam2:'Virtual Auditorium',
    roam3:'Future Science track',
    roam4:'Phygital track',
    roam5:'Operation 4.0 track',
    action1:'Applause',
    action2:'Wave',



    tongdao:"L'Oreal employee exclusive channel registration",
    cs1:"About to transfer to"
}

