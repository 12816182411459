import axios from "axios"
import Bus from "./bus"
import { ConsoleLogger, DefaultDeviceController, DefaultMeetingSession, LogLevel, MeetingSessionConfiguration } from 'amazon-chime-sdk-js';

if (RTCPeerConnection.prototype.getStats) {
    const originalGetStats = RTCPeerConnection.prototype.getStats;
    RTCPeerConnection.prototype.getStats = function (successCallback, failureCallback) {
        originalGetStats.call(this, null).then((stats) => {
            let list = [];
            stats.forEach((report) => {
                let p = report;
                let item = {
                    names: () => { return Object.keys(p); },
                    stat: (key) => { return p[key]; }
                };
                list.push(item);
            });
            successCallback({
                result: () => {
                    return list;
                }
            });
        }).catch((e) => {
            failureCallback(e);
        });
    };
}

class o2vrClientChime {
    constructor() {
        this.clientFlag = false;  //是否已经进入会议室
        this.userId = "";
        this.attendeeId = "";
        this.audioFlag = false;
        this.videoFlag = false;
        this.shareFlag = false;
        this.playerDic = {};
        this.type = 0;              // 0普通 1大会议室
        this.typeFlag = false;      // 大会议室权限
        this.meetingSession = null; // 会议连接
        this.micFlag = false;
        this.cameraFlag = false;
        this.observer = {
            audioVideoDidStart: () => {
                //console.log("audioVideoDidStart");
            },
            audioVideoDidStartConnecting: (reconnecting/*: boolean*/) => {
                //console.log("audioVideoDidStartConnecting");
            },
            audioVideoDidStop: (sessionStatus/*: MeetingSessionStatus*/) => {
                //console.log("audioVideoDidStop");
            },
            audioVideoWasDemotedFromPrimaryMeeting: (status/*: MeetingSessionStatus*/) => {
                //console.log("audioVideoWasDemotedFromPrimaryMeeting");
            },
            connectionDidBecomeGood: () => {
                //console.log("connectionDidBecomeGood");
            },
            connectionDidBecomePoor: () => {
                //console.log("connectionDidBecomePoor");
            },
            connectionDidSuggestStopVideo: () => {
                //console.log("connectionDidSuggestStopVideo");
            },
            connectionHealthDidChange: (connectionHealthData/*: ConnectionHealthData*/) => {
                //console.log("connectionHealthDidChange");
            },
            encodingSimulcastLayersDidChange: (simulcastLayers/*: SimulcastLayers*/) => {
                //console.log("encodingSimulcastLayersDidChange");
            },
            metricsDidReceive: (/*clientMetricReport: ClientMetricReport*/) => {
                //console.log("metricsDidReceive");
            },
            remoteVideoSourcesDidChange: (videoSources/*: VideoSource[]*/) => {
                //console.log("remoteVideoSourcesDidChange");
            },
            videoAvailabilityDidChange: (availability/*: MeetingSessionVideoAvailability*/) => {
                //console.log("videoAvailabilityDidChange");
            },
            videoSendDidBecomeUnavailable: () => {
                //console.log("videoSendDidBecomeUnavailable");
            },
            videoTileDidUpdate: (tileState/*: VideoTileState*/) => {
                console.log("videoTileDidUpdate");
                let attendeeId = tileState.boundAttendeeId;
                if (!attendeeId)
                    return;
                let tileId = tileState.tileId;
                let isShare = attendeeId.indexOf("#") !== -1;
                let localTile = tileState.localTile;
                if (localTile) {
                    if (!this.videoFlag && !isShare) {
                        this.observer.videoTileWasRemoved(tileId);
                        return;
                    }
                    else if (!this.shareFlag && isShare) {
                        this.observer.videoTileWasRemoved(tileId);
                        return;
                    }
                }
                let obj = this.playerDic[attendeeId];
                if (obj && obj.tileId !== tileId) {
                    let oldState = obj.getState();
                    obj.tileId = tileId;
                    obj.play = (videoElement) => {
                        let nodeName = videoElement + "_chime_video";
                        let node = document.getElementById(nodeName);
                        if (node) {
                            this.meetingSession.audioVideo.bindVideoElement(tileId, node);
                        }
                    };
                    obj.notify(oldState);
                }
            },
            videoTileWasRemoved: (tileId/*: number*/) => {
                console.log("videoTileWasRemoved");
                this.meetingSession.audioVideo.unbindVideoElement(tileId, false);
                for (let key in this.playerDic) {
                    let obj = this.playerDic[key];
                    if (obj && obj.tileId === tileId) {
                        let oldState = obj.getState();
                        obj.tileId = 0;
                        delete obj.play;
                        obj.notify(oldState);
                    }
                }
            }
        };

        // 参会者进入退出事件
        this.onAttendeePresence = (attendeeId/*: string*/, present/*: boolean*/, externalUserId/*?: string*/, dropped/*?: boolean*/, posInFrame/*?: RealtimeAttendeePositionInFrame*/) => {
            if (present) {
                console.log("[进入会议]" + attendeeId);
                let tempObj = this.playerDic[attendeeId];
                if (tempObj) {
                    let oldState = tempObj.getState();
                    tempObj.muted = true;
                    tempObj.tileId = 0;
                    tempObj.notify(oldState);
                    this.meetingSession.audioVideo.realtimeUnsubscribeFromVolumeIndicator(attendeeId);
                    delete this.playerDic[attendeeId];
                }
                this.playerDic[attendeeId] = {
                    isMe: externalUserId === this.userId,
                    externalUserId: externalUserId,
                    muted: true,
                    tileId: 0,
                    getUserId: () => { return externalUserId; },
                    getState: () => {
                        let obj = this.playerDic[attendeeId];
                        return {
                            muted: obj.muted,
                            tileId: obj.tileId
                        };
                    },
                    notify: (oldState) => {
                        try {
                            let obj = this.playerDic[attendeeId];
                            let isShare = attendeeId.indexOf("#") !== -1;
                            if (isShare) {
                                if (obj.tileId !== oldState.tileId) {
                                    if (obj.tileId !== 0) {
                                        Bus.$emit('shareScreen', { id: obj.getUserId() });
                                        setTimeout(() => {
                                            obj.play("shareScreen");
                                            Bus.$emit("stream", 'shareScreen');
                                        }, 500);
                                    }
                                    else {
                                        Bus.$emit('shareClose', '');
                                        Bus.$emit("stream", 'screenleave');
                                    }
                                }
                            }
                            else {
                                let oldActive = oldState.tileId !== 0 || !oldState.muted;
                                let newActive = obj.tileId !== 0 || !obj.muted;
                                if (newActive !== oldActive) {
                                    if (newActive) {
                                        Bus.$emit("addlist", obj);
                                        Bus.$emit('listChange2', obj);
                                        setTimeout(() => {
                                            if (obj.play)
                                                obj.play(obj.getUserId());
                                        }, 500);
                                    }
                                    else {
                                        Bus.$emit("removelist", obj);
                                    }
                                }
                                else if (newActive) {
                                    if (oldState.tileId !== obj.tileId || oldState.muted !== obj.muted)
                                        Bus.$emit('listChange2', obj);
                                }
                            }
                        }
                        catch (e) {
                            console.log("err:" + e);
                        }
                    }
                };
                this.meetingSession.audioVideo.realtimeSubscribeToVolumeIndicator(attendeeId, this.volumeIndicator);
            }
            else {
                console.log("[退出会议]" + attendeeId);
                let tempObj = this.playerDic[attendeeId];
                if (tempObj) {
                    let oldState = tempObj.getState();
                    tempObj.muted = true;
                    tempObj.tileId = 0;
                    tempObj.notify(oldState);
                    this.meetingSession.audioVideo.realtimeUnsubscribeFromVolumeIndicator(attendeeId);
                    delete this.playerDic[attendeeId];
                }
            }
        };

        // 参会者音量指示器
        this.volumeIndicator = (attendeeId/*: string*/, volume/*: number | null*/, muted/*: boolean | null*/, signalStrength/*: number | null*/, externalUserId/*?: string*/) => {
            console.log("[会议成员更新]" + attendeeId);

            if (typeof muted !== "boolean")
                return;

            let obj = this.playerDic[attendeeId];
            if (obj && obj.muted !== muted) {
                let oldState = obj.getState();
                obj.muted = muted;
                if (obj.waitId) {
                    clearTimeout(obj.waitId);
                    obj.waitId = 0;
                }
                obj.waitId = setTimeout(() => {
                    let obj2 = this.playerDic[attendeeId];
                    if (obj2) {
                        obj2.waitId = 0;
                        obj2.notify(oldState);
                    }
                }, 500);
            }
        };

        // 本地音频静音状态
        this.localVolumeState = (muted/*: boolean*/) => {
            let obj = this.playerDic[this.attendeeId];
            if (obj && obj.muted !== muted) {
                let oldState = obj.getState();
                obj.muted = muted;
                obj.notify(oldState);
            }
        };

        // 设置本地音频开关
        this.localAudioActive = async (active) => {

            console.info("@@@@audioFlag", active);
            if (active) {
                let stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: false });
                console.info("@@@@@@stream", stream);
                if (stream) {
                    stream.getAudioTracks().forEach((track) => {
                        track.enabled = true;
                    });
                }
                const audioInputDevices = await this.meetingSession.audioVideo.listAudioInputDevices();
                if (audioInputDevices.filter(n => n.label.length > 0).length > 0)
                    await this.meetingSession.audioVideo.chooseAudioInputDevice(audioInputDevices[0].deviceId);
                this.meetingSession.audioVideo.realtimeUnmuteLocalAudio();

            }
            else {
                try {
                    let stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: false });
                    if (stream) {
                        stream.getAudioTracks().forEach((track) => {
                            track.enabled = false;
                        });
                    }
                    // const audioInputDevices = await this.meetingSession.audioVideo.listAudioInputDevices();
                    // if (audioInputDevices.length > 0) {
                    //     await this.meetingSession.audioVideo.chooseAudioInputDevice(audioInputDevices[0].deviceId);
                    // }
                }
                catch (e) {
                    console.info("CLLOG localAudioActive", e);
                }
                finally {
                    await this.meetingSession.audioVideo.chooseAudioInputDevice(null);
                    this.meetingSession.audioVideo.realtimeMuteLocalAudio();
                }
            }
            this.audioFlag = active;
        };

        // 设置本地视频开关
        this.localVideoActive = async (active) => {
            if (active) {
                if (!this.micFlag) {
                    this.meetingSession.audioVideo.start();
                    setTimeout(() => {
                    }, 1000);
                }

                const videoInputDevices = await this.meetingSession.audioVideo.listVideoInputDevices();
                console.info("@@@@@@videoInputDevices", videoInputDevices);
                if (videoInputDevices.filter(n => n.label.length > 0).length > 0)
                    await this.meetingSession.audioVideo.chooseVideoInputDevice(videoInputDevices[0].deviceId);

                this.meetingSession.audioVideo.startLocalVideoTile();
            }
            else {
                this.meetingSession.audioVideo.stopLocalVideoTile();
                await this.meetingSession.audioVideo.chooseVideoInputDevice(null);
            }
            // // 加入房间

            this.videoFlag = active;
        };

        // 设置本地分享开关
        this.localShareActive = async (active) => {
            try {
                if (active)
                    this.meetingSession.audioVideo.startContentShareFromScreenCapture();
                else
                    this.meetingSession.audioVideo.stopContentShare();
                this.shareFlag = active;
            }
            catch (e) {
                this.shareFlag = false;
            }
        };

        // 通知盒子变化
        this.notifyBoxChange = () => {
            if (!this.audioFlag && !this.videoFlag && !this.shareFlag)
                Bus.$emit('localFlag', false);
            else
                Bus.$emit('localFlag', true);
        };
    }

    enter(roomId, userId) {
        //console.log("user: " + userId + " enter room: " + roomId);
        return new Promise((resolve, reject) => {
            axios.post('https://control.protest-plus.com:4000/chime/enter', {
                roomId: roomId,
                userId: userId
            }).then((res) => {
                resolve(res.data);
            }).catch((err) => {
                reject(err.data);
            });
        });
    }

    initPCFlag() {
        this.pcflag = true;
        let mobileAgents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod"];
        let userAgent = navigator.userAgent;
        for (let ix = 0; ix < mobileAgents.length; ++ix) {
            if (userAgent.indexOf(mobileAgents[ix]) !== -1) {
                this.pcflag = false;
                break;
            }
        }
    }

    async login(userId) {
        this.userId = userId;
        this.initPCFlag();
        // CLLOG 20231116
        // if (document.o2.room_id) {
        //     await this.join(document.o2.room_id);
        // }
    }

    async join(roomId) {
        this.clientFlag = false
        // 获取房间信息
        let config = null;
        console.log("userId:" + this.userId + " enter roomId:" + roomId)
        await this.enter(roomId, this.userId).then(res => {
            if (res.code == 200)
                config = res.data;
            else
                console.log("login error: " + res.error);
        });
        if (!config)
            return;

        // 记录ID
        this.roomId = roomId;
        this.attendeeId = config.attendeeResponse.Attendee.AttendeeId;
        this.userId = config.attendeeResponse.Attendee.ExternalUserId;

        // 初始化状态
        this.audioFlag = false;
        this.videoFlag = false;

        // 创建房间会话
        const logger = new ConsoleLogger('MeetingLogs', LogLevel.ERROR);
        const deviceController = new DefaultDeviceController(logger);
        const configuration = new MeetingSessionConfiguration(config.meetingResponse, config.attendeeResponse);
        this.meetingSession = new DefaultMeetingSession(configuration, logger, deviceController);

        // 注册事件
        this.meetingSession.audioVideo.addObserver(this.observer);
        this.meetingSession.audioVideo.realtimeSubscribeToAttendeeIdPresence(this.onAttendeePresence);
        this.meetingSession.audioVideo.realtimeSubscribeToMuteAndUnmuteLocalAudio(this.localVolumeState);

        // 绑定音频对象
        let audioElement = document.getElementById("chime_audio");
        if (audioElement)
            this.meetingSession.audioVideo.bindAudioElement(audioElement);

        // 设置视频质量
        //this.meetingSession.audioVideo.chooseVideoInputQuality(1280, 720, 25);

        // 检测输入设备
        const audioInputDevices = await this.meetingSession.audioVideo.listAudioInputDevices();
        const videoInputDevices = await this.meetingSession.audioVideo.listVideoInputDevices();

        this.micFlag = audioInputDevices.filter(n => n.label.length > 0).length > 0;
        this.cameraFlag = videoInputDevices.filter(n => n.label.length > 0).length > 0;

        // console.info("@@@@V", this.cameraFlag);
        // console.info("@@@@A", this.micFlag);

        // console.info("@@@@V", videoInputDevices);
        // console.info("@@@@A", audioInputDevices);

        // 初始静音
        // await this.localAudioActive(false);

        // 加入房间
        // if (this.micFlag) {
        this.meetingSession.audioVideo.start();
        await this.muteAudio(false);
        // }

        // 广播进入房间
        Bus.$emit('bus_joinChimeRoom', '');
    }

    async loginOut() {
        this.clientFlag = false
        console.log("user: " + this.userId + " exit room: " + this.roomId);
        console.log("meetingSession", this.meetingSession);
        try {
            await this.localAudioActive(false);
        }
        catch { }
        try {
            await this.localVideoActive(false);
        }
        catch { }
        if (this.meetingSession) {

            if (this.pcflag)
                await this.localShareActive(false);
            this.meetingSession.audioVideo.removeObserver(this.observer);
            this.meetingSession.audioVideo.realtimeUnsubscribeToAttendeeIdPresence(this.onAttendeePresence);
            this.meetingSession.audioVideo.realtimeUnsubscribeToMuteAndUnmuteLocalAudio(this.localVolumeState);
            this.meetingSession.audioVideo.unbindAudioElement();
            for (let key in this.playerDic) {
                let tempObj = this.playerDic[key];
                if (tempObj) {
                    let oldState = tempObj.getState();
                    tempObj.muted = true;
                    tempObj.tileId = 0;
                    tempObj.notify(oldState);
                    this.meetingSession.audioVideo.realtimeUnsubscribeFromVolumeIndicator(key);
                    delete this.playerDic[key];
                }
            }
            this.meetingSession.audioVideo.stop();
            await this.meetingSession.destroy();
            this.meetingSession = null;
        }
        document.o2.meettingType = false;
        Bus.$emit('localFlag', false)
    }

    async muteAudio(active) {
        await this.localAudioActive(active);
        this.notifyBoxChange();
    }

    async muteVideo(active) {
        await this.localVideoActive(active);
        this.notifyBoxChange();
    }

    async shareScreen(active) {
        if (this.pcflag) {
            await this.localShareActive(active);
            this.notifyBoxChange();
        }
    }
}

export { o2vrClientChime };