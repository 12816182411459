<template>
  <div id="app" ref="app">
    <router-view />

    <!-- <div class="beian">
      <el-button type="primary" @click="test(true)" v-if="!clientFlag"
        >进入房间</el-button
      >

      <el-button type="warning" @click="test(false)" v-else>退出房间</el-button>
    </div> -->

    <!-- <outLine /> -->
  </div>
</template>

<script>
// import outLine from "./components/outLine.vue";
import { get_infos } from "./assets/api/index";
export default {
  // components: { outLine },
  name: "App",
  data() {
    return {
      clientFlag: false,
      timmer: "",
    };
  },
  methods: {
    test(e) {
      this.clientFlag = e;
      this.Bus.$emit("clientLogin", e);
      if (!e) {
        this.$client.loginOut();
      }
    },
    beforeunloadFn(e) {
      console.log("刷新或关闭");

      this.$chat.ws.close();
      // ...
    },
    screenTest() {
      var width = document.documentElement.clientWidth;

      var userAgentInfo = navigator.userAgent;
      var Agents = ["Android", "iPhone", "SymbianOS", "iPod"];
      var flag = true;

      for (var v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
          flag = false;
          break;
        }
      }

      console.log(flag);
      if (flag) {
        this.$router.push({ name: "login" });
      } else if (!flag && width > 500) {
        this.$router.push({ name: "mobileLogin" });
      } else if (!flag && width < 500) {
        this.$router.push({ name: "mobileTest" });
      }
    },

    getQueryVariable(variable) {
      var url = window.location.href;
      var str = url.split("?");
      if (!str[1]) {
        return;
      }
      var query = str[1];
      var vars = query.split("&");
      console.log(query, str, vars);
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == variable) {
          return pair[1];
        }
      }
      return false;
    },
  },

  async beforeCreate() {
    if (localStorage.getItem("locale")) {
      this.$i18n.locale = localStorage.getItem("locale");
    }

    await get_infos().then((res) => {
      console.log(res);
      document.o2.reportInfo = res[0];
      document.title = res[0].title;
    });
  },

  created() {
    // var lastModified = document.lastModified;
    // if (
    //   !localStorage.getItem("lastModified") ||
    //   lastModified != localStorage.getItem("lastModified")
    // ) {
    //   localStorage.setItem("lastModified", document.lastModified);
    //   window.location.reload(true);
    // } else {
    // }
    // localStorage.setItem("token", "a89699ac-2237-4f7a-a201-6849720a2641");
    // this.$bridge.registerHandler("h5SendAppToken", (res) => {
    //   console.log(res);
    //   res = JSON.parse(res);
    //   if (res.token) {
    //     console.log(res.token, "token");
    //     localStorage.setItem("token", res.token);
    //     this.$router.push({ name: "mobileIndex" });
    //   }
    // });
  },

  mounted() {
    if (this.getQueryVariable("type")) {
      return;
    }
    // this.screenTest();
    // this.$router.push({ name: "mobileIndex" });
    // this.$bridge.callHandler("h5GetApptoken", {}, function (str) {
    //   console.log("mounted");
    // });
    //  this.$bridge.callHandler("h5GetApptoken", {}, function (str) {
    //   console.log("mounted");
    // });
  },

  destroyed() {},
};
</script>

<style lang="scss" scoped>
html {
  -webkit-touch-callout: none; /*系统默认菜单被禁用*/
  -webkit-user-select: none; /*webkit浏览器*/
  -khtml-user-select: none; /*早期浏览器*/
  -moz-user-select: none; /*火狐*/
  -ms-user-select: none; /*IE10*/
  user-select: none;
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-family: "loyFont";
}

#layout-container,
#mainrow,
#main-container {
  margin: auto, 0;
  height: 100%;
  width: 100%;
}
.beian {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  margin: auto;
  color: #fff;
  width: 500px;
}
</style>
