import { player, playerType } from "./player";
import * as THREE from 'three/build/three.module';
import { FBXLoader } from 'three/examples//jsm/loaders/FBXLoader';
import axios from "axios";
import { instanceMgr } from '../instanceMgr'

class playerMgr {
    constructor() {
        console.log('Player Manager');
        this.project_id = 0;
        this.bCfgLoaded = false;
        this.o2 = document.o2;
        this.o2.szrUrl = "https://control.protest-plus.com:4000/config";
        this.playerNode = new THREE.Object3D();
        this.playerNode.name = "PlayerNode";
        this.cur_player = null;
        this.dicPlayer = {};
        this.arrPlayer = [];
        this.arrLoadPlayer = [];
        this.arrPlayerFrameAction = [];
        document.o2.insMgr = new instanceMgr();
        this.playerNode.add(document.o2.insMgr.instanceNode);
        //console.log(this.o2)
        this.o2.frame_move_recall.push(t => {
            this.arrPlayerFrameAction.forEach(action => {
                action(t);
            })
            if (this.bCfgLoaded) {
                //console.log(player.cfg)
                if (this.arrLoadPlayer.length > 0) {
                    let loadPlayer = this.arrLoadPlayer[0];
                    this.arrLoadPlayer.splice(0, 1);
                    let info = loadPlayer.info;
                    let p = loadPlayer.player;
                    p.initPlayer(info, () => {
                        if (p.obj) this.playerNode.add(p.obj);
                        if (loadPlayer.onLoaded)
                            loadPlayer.onLoaded();
                    })
                }
            }
        });
        player.initBaseBone();
        this.getPlayerCfg(this.o2.project_id);
        // player.config = require('http://43.153.24.67:8888/down/WNLPls8NrrGH'); 
        // console.log(player.config);
    }
    //project_id==0 BMW project_id==1 元居 
    import_player(playerInfo) {
        return new Promise((resolve, reject) => {
            if (!playerInfo || !playerInfo.info) {
                reject();
                return;
            }
            if (this.dicPlayer[playerInfo.id]) {
                resolve(this.dicPlayer[playerInfo.id]);
                return;
            }
            let p = this.createPlayer(playerInfo);
            if (p) {
                let loadPlayer = {};
                loadPlayer.info = playerInfo.info;
                loadPlayer.player = p;
                this.arrLoadPlayer.push(loadPlayer);
                console.log(this.arrLoadPlayer);
                loadPlayer.onLoaded = () => {
                    p.loadClip("walk", () => {
                        resolve(p);
                    });
                }
            }
        })
    }
    load_player(loadInfo) {
        //let project_id = loadInfo.project_id;
        let account_id = loadInfo.account_id;
        return new Promise((resolve) => {
            let pType = 0;
            if (account_id <= 1000000) {
                pType = playerType.normal;
            }
            else if (account_id > 1000000 && account_id <= 5000000) {
                pType = playerType.dummy;
            }
            else {
                pType = playerType.visitor;
            }

            this.createPlayer(loadInfo, p => {
                resolve(p);
            });
        })
    }

    getPlayerCfg(id) {
        let url = this.o2.szrUrl + "/config";
        axios.post(url, { 'project_id': id }).then(res => {
            if (res.data) {
                console.log(res.data);
                player.cfg = res.data;
                this.bCfgLoaded = true;
                // if(player.cfg && player.cfg.clips){
                //     let count = player.cfg.clips.length;
                //     this.bCfgLoaded = count == 0;
                //     if(this.bCfgLoaded)return;
                //     player.clips = {};
                //     let fbxLoader = new FBXLoader();
                //     for (let i = 0; i < player.cfg.clips.length; i++) {
                //         let clipUrl = player.cfg.clips[i];
                //         fbxLoader.load(clipUrl,obj=>{
                //             if(obj  && obj.animations.length > 0){
                //                 let clip = obj.animations[0];
                //                 player.clips[i] = clip; 
                //             }
                //             count--;
                //             this.bCfgLoaded = count == 0;
                //         })
                //     }
                // }
            }
        })
    }


    createPlayer(info) {
        if (info && !Number.isNaN(info.id)) {
            if (info.bLoad === undefined) info.bLoad = true;
            if (info.bFake) info.bLoad = false;
            let p = new player(info);
            if (p.onFrame && !p.bFake) {
                this.arrPlayerFrameAction.push(p.onFrame.bind(p));
            }
            this.dicPlayer[info.id] = p;
            if (!info.bFake) this.arrPlayer.push(p);
            return p;
            // p.initPlayer(info.playerInfo,p=>{
            //     if(p.obj) this.playerNode.add(p.obj);
            //     if(action)action(p);
            // })
        }
    }

    getPlayerById(id) {
        return this.dicPlayer[id];
    }
    removePlayer(id) {
        if (this.dicPlayer[id]) {
            let p = this.dicPlayer[id];
            let idx = this.arrPlayerFrameAction.indexOf(p.onFrame.bind(p));
            if (idx != -1) this.arrPlayerFrameAction.splice(idx, 0)
            idx = this.arrPlayer.indexOf(p);
            if (idx != -1) this.arrPlayer.splice(idx, 1);
            delete this.dicPlayer[id];
            if (p.obj && p.obj.parent) p.obj.parent.remove(p.obj);
            p.destroy();
        }
    }
    removeAll() {
        for (const key in this.dicPlayer) {
            let p = this.dicPlayer[key];
            if (p.obj && p.obj.parent) p.obj.parent.remove(p.obj);
            this.dicPlayer[key].destroy();
            delete this.dicPlayer[key];
        }
        //this.dicPlayer = {};
        this.arrPlayerFrameAction.splice(0, this.arrPlayerFrameAction.length);
        this.arrPlayer.splice(0, this.arrPlayer.length);
    }
}
export { playerMgr }